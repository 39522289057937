import Api from '@/providers/api';

/**
 * Fetch an person from api.
 */
async function fetchPerson(project_id, person_id) {
  const response = await Api().get(`projects/${project_id}/people/${person_id}`);
  return response.data.data;
}

export default function usePeople() {
  return {
    fetchPerson,
  };
}
